import './assets/scss/main.scss';

import $ from 'jquery';

window.$ = $;
window.jQuery = $;

import layout from './assets/js/common/layout';
import video from './assets/js/components/video';
import Sticky from './assets/js/components/sticky';

// Layout listener initialization
layout.init();
// Lazy Video initialization
video.init();

// Sticky Component initialization
new Sticky();

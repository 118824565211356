const video = {
    init: function () {
        const lazyVideos = document.querySelectorAll('.js-video[data-lazy-autoplay="true"]');

        const videoObserver = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    const videoEl = entry.target;

                    videoEl.play();
                    observer.unobserve(videoEl);
                }
            });
        }, {
            threshold: 0
        });
        lazyVideos.forEach(video => videoObserver.observe(video));
    }
};

module.exports = video;

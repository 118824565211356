const breakpoints = require('../configs/breakpoints.json');

const viewportWidth = {
    mobile: { maxWidth: breakpoints.sm - 1, minWidth: 0 }, // < 768
    tablet: { maxWidth: breakpoints.md, minWidth: breakpoints.sm }, // => 768 <= 1200
    desktop: { maxWidth: Infinity, minWidth: breakpoints.md + 1 } // > 1200
};

const layout = {
    viewPorts: viewportWidth,
    initCache() {
        this.cache = {
            $window: $(window),
            $document: $(document)
        };
    },
    isMobile: function () {
        return window.matchMedia('(max-width: ' + (viewportWidth.mobile.maxWidth) + 'px)').matches;
    },
    isTablet: function () {
        return window.matchMedia(`(min-width: ${viewportWidth.tablet.minWidth}px) and (max-width: ${viewportWidth.tablet.maxWidth}px)`).matches;
    },
    isDesktop: function () {
        return window.matchMedia('(min-width: ' + (viewportWidth.desktop.minWidth) + 'px)').matches;
    },
    getMode: function () {
        let mode = '';

        if (this.isMobile()) {
            mode = 'mobile';
        } else if (this.isTablet()) {
            mode = 'tablet';
        } else if (this.isDesktop()) {
            mode = 'desktop';
        }

        return mode
    },
    init: function () {
        this.initCache();

        let viewMode = this.getMode();

        window.viewMode = viewMode;

        this.cache.$window.on('resize', () => {
            this.cache.$document.trigger('window.resize');

            const layoutView = this.getMode();
            if (viewMode !== layoutView) {
                viewMode = layoutView;
                window.viewMode = viewMode;
                this.cache.$document.trigger('window.modechanged', { mode: viewMode });
            }
        });
    }
};

module.exports = layout;

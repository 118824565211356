const layout = require('../common/layout');

window.isScrollDirDown = 'isScrollDirDown' in window ? window.isScrollDirDown : true;

class Component {
    constructor($cmpElement) {
        this.config = {
            startPoint: 'self',
            includeHeader: true,
            cmpID: null,
            initOnLayoutModes: null
        };

        this.classes = {
            inited: 'm-inited',
            isStuck: 'm-stuck'
        };

        this.state = {
            isStuck: false,
            lastScrollTop: null,
            startPoint: null
        };

        this.cmpUUID = `cmp_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`;

        this.initCache($cmpElement);
        this.initCoreEvents();
        this.init($cmpElement);
    }

    init($cmpElement) {
        const configData = $cmpElement.data('json-config');

        if (configData) {
            $.extend(this.config, configData);
        }

        if (this.config.initOnLayoutModes?.length && !this.config.initOnLayoutModes.includes(layout.getMode())) {
            return;
        }

        this.state.startPoint = typeof this.config.startPoint === 'number' ? this.config.startPoint : 0;

        this.recalcOffsetPositions();
        this.initEvents();
        this.executeScrollHandlers();

        $cmpElement[0].cmpSticky = this;
        $cmpElement.addClass(this.classes.inited);
    }

    destroy() {
        this.cache.$cmpElement.removeClass(this.classes.inited).removeClass(this.classes.isStuck);
        this.cache.$window.off(`scroll.${this.cmpUUID}`);
        this.cache.$document.off(`window.resize.${this.cmpUUID}`);
    }

    initCache($cmpElement) {
        this.cache = {
            $document: $(document),
            $window: $(window),
            $html: $('html'),
            $body: $('body'),
            $pageHeader: $('.js-header'),
            $cmpElement: $cmpElement
        };
    }

    initCoreEvents() {
        this.cache.$document.on('window.modechanged', (e, data) => {
            if (this.config.initOnLayoutModes?.length) {
                if (this.config.initOnLayoutModes.includes(data.mode)) {
                    this.init(this.cache.$cmpElement);
                } else {
                    this.destroy();
                }
            }
        });
    }

    initEvents() {
        this.cache.$window.on(`scroll.${this.cmpUUID}`, () => {
            this.executeScrollHandlers();
        });

        this.cache.$document.on(`window.resize.${this.cmpUUID}`, () => {
            this.recalcOffsetPositions();
        });
    }

    executeScrollHandlers() {
        if (this.config.showOnScrollUp) {
            this.scrollUpAnimationHandler();
        } else {
            this.stickyHandler();
        }
    }

    scrollUpAnimationHandler() {
        const scrollY = this.cache.$window.scrollTop();

        if (scrollY > this.state.lastScrollTop) {
            // Down
            window.isScrollDirDown = true;

            this.cache.$html.attr('data-scrolldir', 'down');

            if (scrollY > this.cache.bottomOffset && this.config.cmpID) {
                this.cache.$html.addClass(this.config.cmpID + '-bottomoffset-reached');
            }
        } else {
            // Up
            this.cache.$html.attr('data-scrolldir', 'up');

            if (scrollY < this.cache.bottomOffset && this.config.cmpID) {
                this.cache.$html.removeClass(this.config.cmpID + '-bottomoffset-reached');
            }

            window.isScrollDirDown = false;
        }

        this.state.lastScrollTop = scrollY <= 0 ? 0 : scrollY;
    }

    stickyHandler() {
        const scrollTop = this.cache.$window.scrollTop();
        const shouldStick = scrollTop > this.state.startPoint;

        if (shouldStick && !this.state.isStuck) {
            this.stick();
        } else if (!shouldStick && this.state.isStuck) {
            this.unStick();
        }
    }

    stick() {
        this.cache.$cmpElement.addClass(this.classes.isStuck);
        this.state.isStuck = true;
    }

    unStick() {
        this.cache.$cmpElement.removeClass(this.classes.isStuck);
        this.state.isStuck = false;
    }

    recalcOffsetPositions() {
        this.cache.cmpOffsetTop = this.cache.$cmpElement.offset().top;
        if (this.config.startPoint === 'self') {
            this.state.startPoint = this.cache.cmpOffsetTop;
        }
        if (this.config.includeHeader) {
            this.state.startPoint -= this.cache.$pageHeader.outerHeight();
        }
        this.cache.bottomOffset = this.cache.$cmpElement.outerHeight() + this.state.startPoint;
    }
}

/**
 * Initializes the sticky components
 */
function init() {
    $('.js-cmp-sticky').each((index, cmpElement) => {
        new Component($(cmpElement));
    });
}

module.exports = init;
